.header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  // @include mq('desktop')
  //   grid-template-columns: 1fr auto
  &-logo {
    width: 200px;
    @include mq('desktop') {
      width: 250px; } }
  .mobile-icon {
    display: block;
    cursor: pointer;
    img {
      width: 50px; }
    @include mq('desktop') {
      display: none; } }
  &-menu {
    display: none;
    @include mq('desktop') {
      display: block; }
    li {
      display: block;
      @include mq('desktop') {
        display: inline-block; }
      a {
        padding: 20px 10px;
        font-size: 1.5em;
        color: #9C9C9C;
        @include mq('large') {
          padding: 20px 30px; }
        &:hover {
          color: #ffffff; } } } }
  &-isExpanded {
    display: block;
    text-align: center;
    a {
      display: inline-block;
      padding: 15px;
      @inclue mq('desktop') {
        display: none; } } } }
.footer {
  padding-top: 130px;
  padding-bottom: 30px;
  &-logo {
    width: 250px; } }
.footer-top, .footer-bottom {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  @include mq("desktop") {
    grid-template-columns: 1fr 1fr; } }
.footer {
  &-left {
    max-width: 430px;
    &-text {
      font-size: 1.5em;
      color: #9C9C9C; } }
  &-right {
    text-align: right;
    .footer-mail {
      a {
        color: #ffffff; } }
    h2 {
      font-size: 3em;
      margin: 0;
      @include mq("tablet") {
        font-size: 4.5em; } }
    p {
      color: #9C9C9C;
      font-size: 1.5em;
      margin: 10px; } }
  &-bottom {
    align-items: center;
    .footer-social {
      text-align: center;
      margin-top: 30px;
      @include mq("desktop") {
        text-align: left;
        margin-top: 0; }
      img {
        height: 30px; }
      a {
        padding: 10px;
        display: inline-block; } }
    .footer-menu {
      h4 {
        font-size: 1.5em;
        margin-bottom: 10px; }
      li {
        display: inline-block;
        a {
          padding: 20px 40px;
          color: #9C9C9C;
          &:first-child {
            padding-left: 0; }
          &:hover {
            color: #ffffff; } } } }
    .footer-address {
      color: #4D4D4C; } } }
