.home-page {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 50px;
  padding-bottom: 100px;
  @include mq('xlarge') {
    grid-template-columns: repeat(9, 1fr); }
  .hero-text {
    @include mq('desktop') {
      grid-column: 3/6; }
    h1 {
      font-size: 4.5em; }
    p {
      color: #9C9C9C;
      font-size: 1.5em; }
    .hero-buttons {
      li {
        display: inline-block;
        font-size: 1.5em; } } }
  .hero-images {
    display: none;
    @include mq('desktop') {
      display: block;
      grid-column: 6/8; } } }
.main-hero {
  div {
    padding-top: 50px;
    padding-bottom: 100px;
    padding-left: 40px;
    padding-right: 40px;
    @include mq('desktop') {
      padding-left: 200px; } }
  h1 {
    font-size: 2em;
    @include mq('desktop') {
      font-size: 4.5em; } }
  p {
    color: #9C9C9C;
    font-size: 1.5em;
    max-width: 600px; } }
.featured-works {
  background-color: #ffffff;
  color: #252223;
  .container {
    padding: 20px;
    @include mq("tablet") {
      padding: 100px; } }
  h2 {
    font-size: 3.25em; }
  .boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 100px;

    @include mq('desktop') {
      grid-template-columns: 1fr 1fr;
      padding: 0 100px; }
    .box {
      box-shadow: 0 5px 10px rgba(0,0,0,.2);
      border-radius: 10px;
      padding: 20px;
      overflow: hidden;
      cursor: pointer;
      img {
        transition: .5s ease; }
      &:hover {
        img {
          transform: scale(1.05); } } } }
  .btn-box {
    text-align: center;
    margin-top: 30px; } }
.tech-stack {
  .container {
    padding: 50px;
    @include mq("large") {
      padding: 150px 100px; } }
  h2 {
    margin-top: 0;
    font-size: 3.25em;
    @include mq("large") {
      margin-left: 100px; } }
  .boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    @include mq("tablet") {
      grid-template-columns: repeat(2, 1fr); }
    @include mq("large") {
      grid-template-columns: repeat(5, 1fr); }
    .box {
      padding: 20px 30px;
      background-color: #ffffff;
      color: #252223;
      border-radius: 10px;
      display: grid;
      align-items: end;
      img {
        width: 70px; }
      h3 {
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 1.5em; } } } }
.definition {
  background-color: #F84624;
  color: #ffffff;
  .container {
    padding: 50px;
    max-width: 1200px;
    @include mq("large") {
      padding: 200px; } }
  h2 {
    font-size: 3.25em; } }
.featured-blog {
  .container {
    @include mq("large") {
      padding: 100px; } }
  h2 {
    font-size: 3.25em; }
  .boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 100px;
    @include mq('desktop') {
      padding: 0 100px;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 50px; }
    a {
      h3 {
        color: #ffffff;
        font-size: 1.5em; }
      p {
        color: #9C9C9C; } } } }

.cta {
  text-align: center;
  background-color: #ffffff;
  padding: 50px 0;
  .box {
    display: inline-grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 50px;
    background-color: #252223;
    color: #ffffff;
    padding: 50px;
    font-size: 1.4em;
    justify-items: center;
    @include mq('desktop') {
      padding: 40px 150px;
      grid-template-columns: auto auto; }
    &:hover img {
      transform: translateX(20px); }
    img {
      width: 50px;
      transition: .3s ease-in-out; } } }
// About Page
.about-page {
  background-color: #ffffff;
  padding: 100px 0;

  .boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    @include mq('desktop') {
      grid-template-columns: repeat(3, 1fr); }
    .box {
      padding: 20px 40px;
      color: #4D4D4C;
      font-size: 1.5em;
      .title {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        grid-gap: 10px;
        padding: 20px;
        img {
          width: 30px; }
        h3 {
          margin: 0;
          font-size: 1.5em;
          @include mq('desktop') {
            font-size: 2.1em; } } }

      li {
        margin-bottom: 5px; }
      &:first-child {
        background-color: #F5F6F7; } } } }
.about-what-we-do {
  display: grid;
  grid-template-columns: 1fr;
  @include mq('desktop') {
    grid-template-columns: 1fr 1fr; }
  p {
    font-size: 1.5em; }
  h2 {
    font-size: 3.25em;
    margin-bottom: 10px; }
  .web {
    background-color: #FF0255;
    display: grid;
    padding: 50px;
    align-content: center;
    @include mq('desktop') {
      padding: 150px; } }
  .design {
    background-color: #61C5FF;
    padding: 50px; }
  .branding {
    background-color: #FFFFFF;
    color: #4D4D4C;
    padding: 50px; }
  .mobile {
    background-color: #FFD542;
    color: #4D4D4C;
    padding: 50px; }
  .design-other {
    display: grid;
    grid-template-columns: 1fr;
    @include mq('desktop') {
      grid-template-columns: 1fr 1fr; } } }

// Next Page
.next-page {
  background-color: #FBB03C;
  color: #4D4D4C;
  padding: 200px 0;
  .boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
    @include mq('desktop') {
      grid-template-columns: repeat(4, 1fr); }
    .box {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 20px; } }
  h2 {
    font-size: 3.75em; }
  p {
    font-size: 1.5em; }
  .final-step {
    margin-top: 50px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 50px; } }

// Works Page
.works-page {
  .job {
    padding: 100px 0;
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 100px;
      align-items: center;
      .detail {
        h3 {
          font-size: 2.2em; }
        p {
          font-size: 1.5em; } } } }
  .job-1 {
    background-color: #ffffff;
    color: #4D4D4C; }
  .job-2 {
    background-color: #4D4D4C;
    color: #ffffff; }
  .join-us {
    padding: 100px 0;
    background-color: #F84624;
    color: #ffffff;
    .container {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 50px;
      align-items: center;
      h2 {
        font-size: 4.5em; } } } }
// Contact Page
.contact-page {
  .container {

    @include mq('desktop') {
      margin-left: 100px;
      margin-right: 100px; }
    @include mq('xlarge') {
      margin-left: 400px;
      margin-right: 400px; } }
  .title {
    font-size: 2.2em;
    margin-bottom: 20px; }
  .options {
    margin-bottom: 30px;
    li {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 10px; } }
  input[type='radio'], input[type='checkbox'] {
    position: absolute;
    top: -20px;
    overflow: visible;
    &:checked+span {
      background-color: #B10015;
      border: 1px solid #B10015; } }
  label {
    display: block;
    position: relative;
    overflow: hidden; }
  span {
    font-size: 1.5em;
    padding: 10px 30px;
    background-color: #252223;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 10px;
    display: inline-block;
    cursor: pointer;
    &:hover {
      border: 1px solid #4D4D4C;
      background-color: #4D4D4C; } }
  .form-2 {
    grid-template-columns: 1fr;
    @include mq('desktop') {
      grid-template-columns: 1fr 1fr; } }

  .form-1 {
    grid-template-columns: 1fr; }
  .form {
    display: grid;
    grid-gap: 100px;
    font-size: 1.5em;
    margin-top: 70px;
    margin-bottom: 20px;
    .input-group {
      position: relative;
      input[type='text'], input[type='email'] {
        font-size: 1.2em;
        color: #ffffff;
        width: calc(100% - 40px);
        padding: 5px 20px;
        line-height: 1em;
        background: none;
        border: none;
        border-bottom: 2px solid #ffffff;
        background-color: transparent;
        &:focus {
          outline: none; }
        &:focus ~ label, &:not(:placeholder-shown) ~ label {
          top: -20px;
          font-size: 14px;
          color: #e2e2e2; } }

      label {
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all; } } } }

// single-blog-post
.single-blog-post {
  background-color: #ffffff;
  color: #252223;
  .container {
    padding: 20px;
    @include mq('desktop') {
      margin-left: 400px;
      margin-right: 400px; } } }
