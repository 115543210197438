@mixin mq($width: "tablet") {
  @if ($width == "tablet") {
    // Medium devices (tablets, 500px and up)
    @media (min-width: 500px) {
      @content; } }

  @else if ($width == "desktop") {
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      @content; } }

  @else if ($width == "large") {
    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
      @content; } }

  @else if ($width == "xlarge") {
    // Extra large devices (large desktops, 1400px and up)
    @media (min-width: 1400px) {
      @content; } } }
