@import url('https://fonts.googleapis.com/css?family=Muli:400,700');

@import "mixin";
@import "global";
@import "pages";

html, body {
  background-color: #252223;
  font-family: "Muli";
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 12px;
  max-width: 100vw;
  overflow-x: hidden;
  @include mq('tablet') {
    font-size: 16px; } }
a {
  text-decoration: none;
  color: #252223; }
ul {
  list-style: none;
  margin: 0;
  padding: 0; }
img {
  width: 100%; }
.container {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  @include mq("tablet") {
    padding-left: 50px;
    padding-right: 50px; }
  &-fluid {
    width: 100vw; } }
.btn {
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  color: #9C9C9C;
  border: none;
  &:hover {
    background-color: #434241;
    color: #ffffff; }
  &-red {
    background-color: #D0021B;
    color: #ffffff;
    &:hover {
      background-color: #B10015; } }
  &-white {
    background-color: #ffffff;
    color: #252223;
    box-shadow: 0px 10px 10px rgba(0,0,0,.2);
    &:hover {
      background-color: #9c9c9c;
      color: #ffffff; } } }
.text-center {
  text-align: center; }
.text-right {
  text-align: right; }
.text-left {
  text-align: left; }
