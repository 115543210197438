@import url(https://fonts.googleapis.com/css?family=Muli:400,700);
.header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 50px;
  margin-bottom: 50px;
  -webkit-align-items: center;
          align-items: center; }
  .header-logo {
    width: 200px; }
    @media (min-width: 992px) {
      .header-logo {
        width: 250px; } }
  .header .mobile-icon {
    display: block;
    cursor: pointer; }
    .header .mobile-icon img {
      width: 50px; }
    @media (min-width: 992px) {
      .header .mobile-icon {
        display: none; } }
  .header-menu {
    display: none; }
    @media (min-width: 992px) {
      .header-menu {
        display: block; } }
    .header-menu li {
      display: block; }
      @media (min-width: 992px) {
        .header-menu li {
          display: inline-block; } }
      .header-menu li a {
        padding: 20px 10px;
        font-size: 1.5em;
        color: #9C9C9C; }
        @media (min-width: 1200px) {
          .header-menu li a {
            padding: 20px 30px; } }
        .header-menu li a:hover {
          color: #ffffff; }
  .header-isExpanded {
    display: block;
    text-align: center; }
    .header-isExpanded a {
      display: inline-block;
      padding: 15px; }

@inclue mq('desktop') {
  .header-isExpanded a {
    display: none; } }

.footer {
  padding-top: 130px;
  padding-bottom: 30px; }
  .footer-logo {
    width: 250px; }

.footer-top, .footer-bottom {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px; }
  @media (min-width: 992px) {
    .footer-top, .footer-bottom {
      grid-template-columns: 1fr 1fr; } }

.footer-left {
  max-width: 430px; }
  .footer-left-text {
    font-size: 1.5em;
    color: #9C9C9C; }

.footer-right {
  text-align: right; }
  .footer-right .footer-mail a {
    color: #ffffff; }
  .footer-right h2 {
    font-size: 3em;
    margin: 0; }
    @media (min-width: 500px) {
      .footer-right h2 {
        font-size: 4.5em; } }
  .footer-right p {
    color: #9C9C9C;
    font-size: 1.5em;
    margin: 10px; }

.footer-bottom {
  -webkit-align-items: center;
          align-items: center; }
  .footer-bottom .footer-social {
    text-align: center;
    margin-top: 30px; }
    @media (min-width: 992px) {
      .footer-bottom .footer-social {
        text-align: left;
        margin-top: 0; } }
    .footer-bottom .footer-social img {
      height: 30px; }
    .footer-bottom .footer-social a {
      padding: 10px;
      display: inline-block; }
  .footer-bottom .footer-menu h4 {
    font-size: 1.5em;
    margin-bottom: 10px; }
  .footer-bottom .footer-menu li {
    display: inline-block; }
    .footer-bottom .footer-menu li a {
      padding: 20px 40px;
      color: #9C9C9C; }
      .footer-bottom .footer-menu li a:first-child {
        padding-left: 0; }
      .footer-bottom .footer-menu li a:hover {
        color: #ffffff; }
  .footer-bottom .footer-address {
    color: #4D4D4C; }

.home-page {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 50px;
  padding-bottom: 100px; }
  @media (min-width: 1400px) {
    .home-page {
      grid-template-columns: repeat(9, 1fr); } }
  @media (min-width: 992px) {
    .home-page .hero-text {
      grid-column: 3/6; } }
  .home-page .hero-text h1 {
    font-size: 4.5em; }
  .home-page .hero-text p {
    color: #9C9C9C;
    font-size: 1.5em; }
  .home-page .hero-text .hero-buttons li {
    display: inline-block;
    font-size: 1.5em; }
  .home-page .hero-images {
    display: none; }
    @media (min-width: 992px) {
      .home-page .hero-images {
        display: block;
        grid-column: 6/8; } }

.main-hero div {
  padding-top: 50px;
  padding-bottom: 100px;
  padding-left: 40px;
  padding-right: 40px; }
  @media (min-width: 992px) {
    .main-hero div {
      padding-left: 200px; } }

.main-hero h1 {
  font-size: 2em; }
  @media (min-width: 992px) {
    .main-hero h1 {
      font-size: 4.5em; } }

.main-hero p {
  color: #9C9C9C;
  font-size: 1.5em;
  max-width: 600px; }

.featured-works {
  background-color: #ffffff;
  color: #252223; }
  .featured-works .container {
    padding: 20px; }
    @media (min-width: 500px) {
      .featured-works .container {
        padding: 100px; } }
  .featured-works h2 {
    font-size: 3.25em; }
  .featured-works .boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 100px; }
    @media (min-width: 992px) {
      .featured-works .boxes {
        grid-template-columns: 1fr 1fr;
        padding: 0 100px; } }
    .featured-works .boxes .box {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 20px;
      overflow: hidden;
      cursor: pointer; }
      .featured-works .boxes .box img {
        transition: .5s ease; }
      .featured-works .boxes .box:hover img {
        -webkit-transform: scale(1.05);
                transform: scale(1.05); }
  .featured-works .btn-box {
    text-align: center;
    margin-top: 30px; }

.tech-stack .container {
  padding: 50px; }
  @media (min-width: 1200px) {
    .tech-stack .container {
      padding: 150px 100px; } }

.tech-stack h2 {
  margin-top: 0;
  font-size: 3.25em; }
  @media (min-width: 1200px) {
    .tech-stack h2 {
      margin-left: 100px; } }

.tech-stack .boxes {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px; }
  @media (min-width: 500px) {
    .tech-stack .boxes {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 1200px) {
    .tech-stack .boxes {
      grid-template-columns: repeat(5, 1fr); } }
  .tech-stack .boxes .box {
    padding: 20px 30px;
    background-color: #ffffff;
    color: #252223;
    border-radius: 10px;
    display: grid;
    -webkit-align-items: end;
            align-items: end; }
    .tech-stack .boxes .box img {
      width: 70px; }
    .tech-stack .boxes .box h3 {
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 1.5em; }

.definition {
  background-color: #F84624;
  color: #ffffff; }
  .definition .container {
    padding: 50px;
    max-width: 1200px; }
    @media (min-width: 1200px) {
      .definition .container {
        padding: 200px; } }
  .definition h2 {
    font-size: 3.25em; }

@media (min-width: 1200px) {
  .featured-blog .container {
    padding: 100px; } }

.featured-blog h2 {
  font-size: 3.25em; }

.featured-blog .boxes {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 100px; }
  @media (min-width: 992px) {
    .featured-blog .boxes {
      padding: 0 100px;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 50px; } }
  .featured-blog .boxes a h3 {
    color: #ffffff;
    font-size: 1.5em; }
  .featured-blog .boxes a p {
    color: #9C9C9C; }

.cta {
  text-align: center;
  background-color: #ffffff;
  padding: 50px 0; }
  .cta .box {
    display: inline-grid;
    grid-template-columns: 1fr;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 50px;
    background-color: #252223;
    color: #ffffff;
    padding: 50px;
    font-size: 1.4em;
    justify-items: center; }
    @media (min-width: 992px) {
      .cta .box {
        padding: 40px 150px;
        grid-template-columns: auto auto; } }
    .cta .box:hover img {
      -webkit-transform: translateX(20px);
              transform: translateX(20px); }
    .cta .box img {
      width: 50px;
      transition: .3s ease-in-out; }

.about-page {
  background-color: #ffffff;
  padding: 100px 0; }
  .about-page .boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px; }
    @media (min-width: 992px) {
      .about-page .boxes {
        grid-template-columns: repeat(3, 1fr); } }
    .about-page .boxes .box {
      padding: 20px 40px;
      color: #4D4D4C;
      font-size: 1.5em; }
      .about-page .boxes .box .title {
        display: grid;
        grid-template-columns: auto 1fr;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 10px;
        padding: 20px; }
        .about-page .boxes .box .title img {
          width: 30px; }
        .about-page .boxes .box .title h3 {
          margin: 0;
          font-size: 1.5em; }
          @media (min-width: 992px) {
            .about-page .boxes .box .title h3 {
              font-size: 2.1em; } }
      .about-page .boxes .box li {
        margin-bottom: 5px; }
      .about-page .boxes .box:first-child {
        background-color: #F5F6F7; }

.about-what-we-do {
  display: grid;
  grid-template-columns: 1fr; }
  @media (min-width: 992px) {
    .about-what-we-do {
      grid-template-columns: 1fr 1fr; } }
  .about-what-we-do p {
    font-size: 1.5em; }
  .about-what-we-do h2 {
    font-size: 3.25em;
    margin-bottom: 10px; }
  .about-what-we-do .web {
    background-color: #FF0255;
    display: grid;
    padding: 50px;
    -webkit-align-content: center;
            align-content: center; }
    @media (min-width: 992px) {
      .about-what-we-do .web {
        padding: 150px; } }
  .about-what-we-do .design {
    background-color: #61C5FF;
    padding: 50px; }
  .about-what-we-do .branding {
    background-color: #FFFFFF;
    color: #4D4D4C;
    padding: 50px; }
  .about-what-we-do .mobile {
    background-color: #FFD542;
    color: #4D4D4C;
    padding: 50px; }
  .about-what-we-do .design-other {
    display: grid;
    grid-template-columns: 1fr; }
    @media (min-width: 992px) {
      .about-what-we-do .design-other {
        grid-template-columns: 1fr 1fr; } }

.next-page {
  background-color: #FBB03C;
  color: #4D4D4C;
  padding: 200px 0; }
  .next-page .boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px; }
    @media (min-width: 992px) {
      .next-page .boxes {
        grid-template-columns: repeat(4, 1fr); } }
    .next-page .boxes .box {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 20px; }
  .next-page h2 {
    font-size: 3.75em; }
  .next-page p {
    font-size: 1.5em; }
  .next-page .final-step {
    margin-top: 50px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 50px; }

.works-page .job {
  padding: 100px 0; }
  .works-page .job .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    -webkit-align-items: center;
            align-items: center; }
    .works-page .job .container .detail h3 {
      font-size: 2.2em; }
    .works-page .job .container .detail p {
      font-size: 1.5em; }

.works-page .job-1 {
  background-color: #ffffff;
  color: #4D4D4C; }

.works-page .job-2 {
  background-color: #4D4D4C;
  color: #ffffff; }

.works-page .join-us {
  padding: 100px 0;
  background-color: #F84624;
  color: #ffffff; }
  .works-page .join-us .container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 50px;
    -webkit-align-items: center;
            align-items: center; }
    .works-page .join-us .container h2 {
      font-size: 4.5em; }

@media (min-width: 992px) {
  .contact-page .container {
    margin-left: 100px;
    margin-right: 100px; } }

@media (min-width: 1400px) {
  .contact-page .container {
    margin-left: 400px;
    margin-right: 400px; } }

.contact-page .title {
  font-size: 2.2em;
  margin-bottom: 20px; }

.contact-page .options {
  margin-bottom: 30px; }
  .contact-page .options li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px; }

.contact-page input[type='radio'], .contact-page input[type='checkbox'] {
  position: absolute;
  top: -20px;
  overflow: visible; }
  .contact-page input[type='radio']:checked + span, .contact-page input[type='checkbox']:checked + span {
    background-color: #B10015;
    border: 1px solid #B10015; }

.contact-page label {
  display: block;
  position: relative;
  overflow: hidden; }

.contact-page span {
  font-size: 1.5em;
  padding: 10px 30px;
  background-color: #252223;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer; }
  .contact-page span:hover {
    border: 1px solid #4D4D4C;
    background-color: #4D4D4C; }

.contact-page .form-2 {
  grid-template-columns: 1fr; }
  @media (min-width: 992px) {
    .contact-page .form-2 {
      grid-template-columns: 1fr 1fr; } }

.contact-page .form-1 {
  grid-template-columns: 1fr; }

.contact-page .form {
  display: grid;
  grid-gap: 100px;
  font-size: 1.5em;
  margin-top: 70px;
  margin-bottom: 20px; }
  .contact-page .form .input-group {
    position: relative; }
    .contact-page .form .input-group input[type='text'], .contact-page .form .input-group input[type='email'] {
      font-size: 1.2em;
      color: #ffffff;
      width: calc(100% - 40px);
      padding: 5px 20px;
      line-height: 1em;
      background: none;
      border: none;
      border-bottom: 2px solid #ffffff;
      background-color: transparent; }
      .contact-page .form .input-group input[type='text']:focus, .contact-page .form .input-group input[type='email']:focus {
        outline: none; }
      .contact-page .form .input-group input[type='text']:focus ~ label, .contact-page .form .input-group input[type='text']:not(:placeholder-shown) ~ label, .contact-page .form .input-group input[type='email']:focus ~ label, .contact-page .form .input-group input[type='email']:not(:placeholder-shown) ~ label {
        top: -20px;
        font-size: 14px;
        color: #e2e2e2; }
    .contact-page .form .input-group label {
      position: absolute;
      pointer-events: none;
      left: 5px;
      top: 10px;
      transition: 0.2s ease all; }

.single-blog-post {
  background-color: #ffffff;
  color: #252223; }
  .single-blog-post .container {
    padding: 20px; }
    @media (min-width: 992px) {
      .single-blog-post .container {
        margin-left: 400px;
        margin-right: 400px; } }

html, body {
  background-color: #252223;
  font-family: "Muli";
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 12px;
  max-width: 100vw;
  overflow-x: hidden; }
  @media (min-width: 500px) {
    html, body {
      font-size: 16px; } }

a {
  text-decoration: none;
  color: #252223; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

img {
  width: 100%; }

.container {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 500px) {
    .container {
      padding-left: 50px;
      padding-right: 50px; } }
  .container-fluid {
    width: 100vw; }

.btn {
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  color: #9C9C9C;
  border: none; }
  .btn:hover {
    background-color: #434241;
    color: #ffffff; }
  .btn-red {
    background-color: #D0021B;
    color: #ffffff; }
    .btn-red:hover {
      background-color: #B10015; }
  .btn-white {
    background-color: #ffffff;
    color: #252223;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2); }
    .btn-white:hover {
      background-color: #9c9c9c;
      color: #ffffff; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

